


























































import { Component, Vue } from 'vue-property-decorator';
import { Message } from 'element-ui';

import QuestionForm from '@/components/Form.vue';
import { getQuestion, resetQuestion } from '@/firebase/models/daily';
import { questionOfTheDay } from '@/api/daily';

@Component({
  components: {
    QuestionForm,
  },
})
export default class Dashboard extends Vue {
  loading = true;
  datePicked = new Date();
  pickerOptions = {
    disabledDate(time: any) {
      return time.getTime() > Date.now();
    },
  };

  question: any = {};

  action(value: string) {
    getQuestion(value).then((question: any) => {
      if (!question.error) {
        this.question = question;
      } else {
        Message({
          type: 'error',
          message: question.error,
        });
      }
    });
  }

  reset() {
    resetQuestion().then((response: any) => {
      if (response.success) {
        this.datePicked = new Date();
        questionOfTheDay().then((question: any) => (this.question = question));
        Message({
          type: 'success',
          message: response.success,
        });
      } else {
        Message({
          type: 'error',
          message: response.error,
        });
      }
    });
  }

  edit() {
    this.$router.push(`/learning-feature/edit/${this.question.objectID}`);
  }

  created() {
    questionOfTheDay().then((question: any) => {
      this.question = question;
      this.loading = false;
    });
  }
}
