import { dailyCollection } from '@/firebase';
import moment from 'moment';

const getQuestion = async (id: string) => {
  const question = await dailyCollection.doc(id).get();
  if (question.exists) {
    return question.data();
  }
  return { error: 'A question does not exist for this day' };
};

const resetQuestion = async () => {
  return dailyCollection
    .doc(moment().format('YYYYMMDD').toString())
    .delete()
    .then(() => {
      return {
        success: "Today's question has been successfully reset",
      };
    })
    .catch(() => {
      return {
        error: 'Something went wrong, please try again later',
      };
    });
};

export { getQuestion, resetQuestion };
